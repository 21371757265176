import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../../infrastructure/auth/user';
import {UserService} from '../../../../infrastructure/auth/user.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input()
  user!: User | undefined;

  @Input()
  size = 'sm';

  imageSrc!: string;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  getLoadLocalUser(): User | undefined {
    return this.user || this.userService.loadLocalUser();
  }

  uploadAvatar(e: any): void {
    if (this.user) {
      return;
    }

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any): void {
    const reader = e.target;
    this.imageSrc = reader.result;

    this.updateAvatar(this.imageSrc);
  }

  deleteAvatar(): void {
    this.updateAvatar(undefined);
  }

  private updateAvatar(avatar: string | undefined): void {

    console.log('TESTE: ', avatar);
    this.userService.updateAvatar(avatar).then(u => {
      console.log('TESTE2: ', u);
      const localUser = this.getLoadLocalUser();
      console.log('TESTE3: ', localUser);
      if (localUser) {
        localUser.avatar = avatar;
        this.userService.saveLocalUser(localUser);
      }
    });
  }

  getAvatarClassName(size: string): string {
    return `avatar avatar-${size} rounded-circle`;
  }
}
