<span [className]="getAvatarClassName(size)" *ngIf="size !== 'xl'">
  <img
    *ngIf="getLoadLocalUser()?.avatar"
    alt="{{ getLoadLocalUser()?.name }}"
    [title]="getLoadLocalUser()?.name"
    src="{{getLoadLocalUser()?.avatar}}"
    class="rounded-circle avatar-sm"
  />
  <div *ngIf="!getLoadLocalUser()?.avatar">
    {{getLoadLocalUser()?.name?.substring(0, 1)?.toUpperCase()}}
  </div>
</span>

<div class="card-profile-image icon-wrapper" *ngIf="size === 'xl'">
  <a href="javascript:void(0)">
    <img
      *ngIf="getLoadLocalUser()?.avatar"
      alt="Image placeholder"
      src="{{getLoadLocalUser()?.avatar}}"
      class="rounded-circle avatar-xl"
      [matTooltip]="'core.avatar.upload' | translate"
      (click)="avatarInputFile.click()"
    />
    <img
      *ngIf="!getLoadLocalUser()?.avatar"
      src="assets/img/theme/avatar-bg.png"
      class="rounded-circle avatar-xl"
      [matTooltip]="'core.avatar.upload' | translate"
      (click)="avatarInputFile.click()"
    />
  </a>

  <div *ngIf="getLoadLocalUser()?.avatar"
       (click)="deleteAvatar()"
       [matTooltip]="'core.avatar.remove' | translate"
       class="badge badge-primary badge-circle badge-danger  avatar-remove-btn ">
    <i class="fa-solid fa-xmark"></i>
  </div>

  <input type="file" #avatarInputFile accept="image/*" (change)="uploadAvatar($event)" />
</div>


