<div class="d-flex flex-column" *ngIf="!showNewTenantInput && !creating">
  <div class=" dropdown-header noti-title text-center">
    <h6 class="text-overflow m-0">{{ 'core.switch_tenant.title' | translate }}</h6>
  </div>

  <a class="dropdown-item mt-2 cursor-pointer" (click)="switchTenant(userTenant)" *ngFor="let userTenant of userTenants">
    <span class="avatar avatar-sm rounded-circle mr-2">
      <img *ngIf="userTenant.user?.avatar" alt="Image placeholder" src="{{userTenant.user.avatar}}" class="rounded-circle">
      <div *ngIf="!userTenant.user?.avatar">{{userTenant.user?.name?.substring(0,1)?.toUpperCase()}}</div>
    </span>
    <span>{{userTenant.tenant.name}}</span>
  </a>

  <button class="btn btn-primary mt-3" (click)="showNewTenantInput = true" *ngIf="!showNewTenantInput">{{ 'core.switch_tenant.create_new' | translate }}</button>
</div>

<div class="d-flex flex-column" *ngIf="showNewTenantInput && !creating">
  <div class=" dropdown-header noti-title text-center">
    <h6 class="text-overflow m-0">{{ 'core.switch_tenant.create_new' | translate }}</h6>
  </div>

  <app-input-text [formGroup]="formGroupNewTenant" dbname="tenant_name" description="New workspace name"></app-input-text>
  <button class="btn btn-primary" (click)="createNewTenant()">Create new</button>
</div>

<!-- TODO: Modificar esta rotina para um loading que deve ser criado no futuro -->
<div class="d-flex flex-column align-items-center justify-content-center" *ngIf="creating">
    <mat-progress-spinner
      color="info"
      mode="indeterminate">
    </mat-progress-spinner>
  <div class="text-center">
    <h2>{{ 'core.switch_tenant.creating' | translate }}</h2>
  </div>
</div>
